import React, { useMemo, useState } from "react";

import SampleCodeItem from "../../components/SampleCodeItem";

import { getSamples } from "../../services/SampleCodeService";

import { navigate, StaticQuery, graphql } from "gatsby";

import { getPathInfo, formatStringForId, getQueryString } from "../../services/URL";
import { WEB } from "../../../config/platforms";
import Helmet from "react-helmet";

import { Flex, Input } from "@chakra-ui/react";
import { Box, Heading, Text } from "@chakra-ui/layout";
import { Select } from "@chakra-ui/select";

const DropdownOptions = [
  { title: "All", value: "all" },
  { title: "Web", value: "web" },
  { title: "Android", value: "android" },
  { title: "iOS", value: "ios" },
  { title: "UWP", value: "uwp" },
  { title: "Xamarin", value: "xamarin" },
  { title: "Linux", value: "linux" },
  { title: "Windows", value: "windows" },
  { title: "macOS", value: "mac" },
  { title: ".NET", value: "dotnet" },
  { title: "Node.js", value: "nodejs" },
  { title: "Java", value: "java" },
  { title: "C++", value: "cpp" },
  { title: "PHP", value: "php" },
  { title: "Python", value: "python" },
  { title: "Ruby", value: "ruby" },
  { title: "Go", value: "go" },
];

function SampleCodeList({ location, samples }) {
  const [textFilter, setTextFilter] = useState("");
  const { platform } = getPathInfo(location.pathname, location) || {};

  let filteredData = useMemo(() => {
    const samplesList = getSamples(samples, location).sort((a, b) =>
      a.meta.title.localeCompare(b.meta.title)
    );
    return platform === "all" || !platform
      ? samplesList
      : samplesList.filter((data) => {
          return (
            !!data.meta &&
            data.platforms?.includes(platform) &&
            !data.meta.ignoredPlatforms?.includes(platform)
          );
        });
  }, [samples, location, platform]);

  const pageInfo =
    typeof window === "undefined" ? {} : getPathInfo(window.location.pathname, window.location);

  // TODO can reenable this
  // const functionalities = useMemo(() => {
  //   return getFunctionalities(samples, pageInfo.platform || 'all');
  // }, [samples, pageInfo.platform])

  if (textFilter) {
    filteredData = filteredData.filter((item) => {
      return (
        item.meta.title.toLowerCase().indexOf(textFilter) > -1 ||
        item.meta.description.toLowerCase().indexOf(textFilter) > -1 ||
        (item.meta.subtitle || "").toLowerCase().indexOf(textFilter) > -1
      );
    });
  }

  const select = (e) => {
    const platform = e.target.value;

    if (platform === "all") {
      navigate("/samples/");
    } else if (platform === WEB) {
      navigate("/web/samples/viewing/");
    } else {
      const query = getQueryString(
        window.location,
        "platforms",
        platform === "all" ? false : platform
      );
      navigate(window.location.pathname + query);
    }
  };

  return (
    <Box>
      <Helmet>
        <title>Apryse Samples: View, Annotate, Edit & More | Apryse Documentation</title>
        <meta
          name="description"
          content="Find 60+ code samples to help you get started with Apryse's SDK. Filter by platform or search for specific functionality"
        />
      </Helmet>

      <Box w="100%" pl="10px" maxWidth="700px" margin="0 auto">
        <Heading as="h1" size="h1">
          Apryse Samples
        </Heading>
        <Box bg="gray.300" padding="20px" borderRadius="4px" mb="50px">
          <Heading size="sm" mb="10px">
            Filters
          </Heading>
          <Flex className="filters">
            <Box>
              <Text>Platform</Text>
              <Select onChange={select} defaultValue={pageInfo.platform || "all"}>
                {DropdownOptions.map((o) => {
                  return (
                    <option value={o.value} key={o.title}>
                      {o.title}
                    </option>
                  );
                })}
              </Select>
            </Box>
            <Box ml="20px" flexGrow={1}>
              <Text>Filter by text</Text>
              <Input
                type="text"
                onChange={(e) => setTextFilter(e.target?.value?.toLowerCase() || "")}
                defaultValue={textFilter}
              ></Input>
            </Box>
          </Flex>
        </Box>

        <Box>
          {filteredData.map((info) => {
            return (
              <SampleCodeItem
                id={formatStringForId(info.meta.title)}
                key={info.meta.title}
                data={info}
                location={location}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default function SampleCodeListWrapped(props) {
  return (
    <StaticQuery
      query={graphql`
        query SampleCodeQuery {
          allDocumentationServiceField {
            edges {
              node {
                internal {
                  content
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <SampleCodeList
          samples={JSON.parse(data.allDocumentationServiceField.edges[0].node.internal.content)}
          {...props}
        />
      )}
    />
  );
}
