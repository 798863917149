import { LANGUAGE_PLATFORMS } from '../../config/languages';
import intersection from 'lodash/intersection';
import union from 'lodash/union';

import { queryToObject } from './URL';

const getLanguagesForPlatforms = (platforms) => {
  return Object.keys(LANGUAGE_PLATFORMS).reduce((acc, key) => {
    const value = LANGUAGE_PLATFORMS[key];
    if (intersection(value, platforms).length > 0) {
      acc = union(acc, [key]);
    }
    return acc;
  }, [])
}

export const getFunctionalities = (samples, platform) => {
  let groups = Object.values({ ...samples }).slice(0);

  let obj = {};

  groups.forEach(group => {
    if (!group.meta) return;
    if (!group.meta.functionality) return;

    group.meta.functionality.forEach(func => {

      if (platform === 'all') {
        obj[func] = true;
      } else if((group.platforms || []).indexOf(platform) > -1) {
        obj[func] = true;
      }

    })

  })

  return Object.keys(obj);
}

export const getSamples = (samples, location) => {
  let groups = Object.values({ ...samples }).slice(0);
  const filters = queryToObject(location);

  groups = groups.filter((group) => {
    let canShow = true;
    const platforms = group.platforms;
    if (!group.meta) return false;

    const languages = group.languages;
    let supportedLanguages = languages;

    if (filters.functionality && filters.functionality !== 'all') {
      if (!group.meta.functionality) return false;
      canShow = group.meta.functionality.some((functionality) => {
        return filters.functionality.indexOf(functionality) > -1;
      });
    }

    if (filters.platforms) {
      canShow = platforms.some((platform) => {
        return filters.platforms.indexOf(platform) > -1;
      });
      const platformLangs = getLanguagesForPlatforms(filters.platforms);
      supportedLanguages = languages.filter(l => platformLangs.indexOf(l) > -1);
      if (supportedLanguages.length === 0) {
        return false
      }
    }

    if (filters.languages) {
      supportedLanguages = filters.languages;

      canShow = languages.some((lang) => {
        return filters.languages.indexOf(lang) > -1;
      });
    }

    if ((!filters.platforms || filters.platforms === 'all') && supportedLanguages.length === 1 && supportedLanguages[0] === 'js') {
      canShow = false;
    }

    group.supportedLanguages = supportedLanguages;
    return canShow;
  });

  return groups;
}
