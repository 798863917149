import React, { useEffect, useState } from 'react';
import { getUserFacingLanguage } from '../../../processing/text';
import JumpTo from '../JumpTo';
import { Box, Flex, Heading, Text } from '@chakra-ui/layout';
import Link from '../Link';
import { Skeleton } from '@chakra-ui/react';

type SampleCodeItemProps = {
  data: any;
  location: Location;
  id: string;
};

export default function SampleCodeItem({
  data,
  location,
  id,
}: SampleCodeItemProps) {
  const [links, setLinks] = useState(null);
  const { meta, supportedLanguages, fileName } = data;
  const { search, pathname } = location;

  useEffect(() => {
    const languageData = supportedLanguages.slice();
    const sortedLanguages = languageData.sort((a, b) =>
      getUserFacingLanguage(a).localeCompare(getUserFacingLanguage(b))
    );

    const sortedLinks = sortedLanguages.map((language, index) => {
      const replacedLanguage = language.replace('.', '/');
      const langPath =
        !pathname.endsWith("/") && !replacedLanguage.startsWith("/")
          ? `${pathname}/${replacedLanguage}`
          : `${pathname}${replacedLanguage}`;

      const link = `${langPath}/${fileName}${
        search ? search : ''
      }`;
      
      return (
        <Link blue mr='8px' to={link} key={`${link}-${index}`}>
          {getUserFacingLanguage(language)}
        </Link>
      );
    });
    setLinks(sortedLinks);
  }, [search, pathname]);

  return (
    <Box mb='40px' key={id}>
      <JumpTo id={id}>
        <Heading size='h3'>{meta.title}</Heading>
      </JumpTo>

      {meta.subtitle && (
        <Heading size='h5' as='h5' key={`${id}-${meta.subtitle}`}>
          {meta.subtitle}
        </Heading>
      )}

      {meta.description && (
        <Text my='10px' key={`${id}-${meta.description}`}>
          {meta.description}
        </Text>
      )}

      {links ? (
        <Flex flexWrap='wrap' key={`${id}-supportedLanguages}`}>
          {links}
        </Flex>
      ) : (
        <>
          <Skeleton h='16px' />
          <Skeleton h='16px' mt={2} />
        </>
      )}
    </Box>
  );
}
